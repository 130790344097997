import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/blue_hair.jpg'
import pic02 from '../assets/images/loreal.jpg'
import pic03 from '../assets/images/girl.jpg'
import pic04 from '../assets/images/nail_polish.jpg'

const hidden = {
  display: 'none',
}

const space_top = {
  margin: '1em 0em',
}

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Thanh Le College, School of Cosmetology"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        />

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Cosmetology</h3>
                <p>
                  The course of study for Cosmetology consists of 1600 clock
                  hours and explores all facets of cosmetology, skin care,
                  manicure and pedicure.
                </p>
              </header>
              <Link to="/cosmetology" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Cosmetician</h3>
                <p>
                  The course of study for Cosmetician consists of 600 clock
                  hours covering all aspects of skin care.
                </p>
              </header>
              <Link to="/cosmetician" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Cosmetology Instructor Trainee</h3>
                <p>
                  The Cosmetology instructor course consists of 600 clock hours
                  covering all aspects of teaching the art of Cosmetology.
                </p>
              </header>
              <Link to="/cosmetology-instructor" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Manicuring</h3>
                <p>
                  The Manicuring course consists of 400 clock hours covering all
                  aspects of manicure and pedicure.
                </p>
              </header>
              <Link to="/manicuring" className="link primary" />
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Thanh Le College, School of Cosmetology</h2>
              </header>
              <p>
                "There is no such thing as natural beauty."- Memorable quote
                from Steel Magnolias (1989)
              </p>
              <p>
                We at Thanh Le College, School of Cosmetology feel that
                education is of pinnacle importance. Our faculty and staff are
                selected based on their abilities as well as their desires to
                help the students to achieve their educational goals.
              </p>
              <p>Tuesday - Saturday 9:00am to 3:00pm.</p>
              <p>
                Visit our office during these business hours or to make an
                appointment, please call (714) 740-2755.
              </p>
              <ul className="actions">
                <li>
                  <Link
                    to="/school-catalog"
                    className="button small"
                    style={space_top}
                  >
                    School Catalog (2024)
                  </Link>
                </li>
                <li>
                  <a
                    href="http://bppe.ca.gov"
                    className="button small"
                    style={space_top}
                  >
                    bppe.ca.gov
                  </a>
                </li>
                <li>
                  <Link
                    className="button small"
                    to="/performance-fact-sheet/performance"
                    style={space_top}
                  >
                    School Performance Fact Sheet
                  </Link>
                </li>
                <li>
                  <Link
                    className="fit button small"
                    to="/bppe-disclosures"
                    style={space_top}
                  >
                    BPPE Annual Report 2022
                  </Link>
                </li>
                <li>
                  <Link
                    className="button small"
                    to="/naccas-annual-report"
                    style={space_top}
                  >
                    {' '}
                    2022 NACCAS Annual Report{' '}
                  </Link>
                </li>
              </ul>

              <ul className="actions">
                <li>
                  <Link
                    to="/school-catalog"
                    className="button next"
                    style={hidden}
                  >
                    School Catalog
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
