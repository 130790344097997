import React from 'react'
import { Link } from 'gatsby'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Thanh Le College, School of Cosmetology</h1>
      </header>
      <div className="content">
        <p>
          Thanh Le College, School of Cosmetology is determined to always offer
          a strong curriculum in the field of cosmetology and to maintain that
          curriculum with the most modern techniques and the freshest styles.
          Select a program to get started.
        </p>
        <ul className="actions">
          <li>
            <a href="#one" className="button next scrolly">
              Programs
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
